const DefaultTime = 5000

let successMessage = (string, delay) => {
  return new Promise((resolve) => {
    window._Vue.$toast.open({
      message: string,
      position: 'top',
      type: 'success',
      duration: (delay >= 0) ? delay : DefaultTime,
      dismissible: true
    })

    setTimeout(() => {
      resolve()
    }, (delay >= 0) ? delay : DefaultTime)
  })
}

let InformationMessage = (string, delay) => {
  return new Promise((resolve) => {
    window._Vue.$toast.open({
      message: string,
      position: 'top',
      type: 'info',
      duration: (delay >= 0) ? delay : DefaultTime,
      dismissible: true
    })
    setTimeout(() => {
      resolve()
    }, (delay >= 0) ? delay : DefaultTime)
  })
}

let errorMessage = (string, delay) => {
  window._Vue.$toast.open({
    message: string,
    position: 'top',
    type: 'error',
    duration: (delay >= 0) ? delay : DefaultTime,
    dismissible: true
  })
}

let serverErrorMessage = (string, delay) => {
  window._Vue.$toast.open({
    message: string,
    position: 'top',
    type: 'error',
    duration: (delay >= 0) ? delay : DefaultTime,
    dismissible: true
  })
}

let warningMessage = (string, delay) => {
  window._Vue.$toast.open({
    message: string,
    position: 'top',
    type: 'warning',
    duration: (delay >= 0) ? delay : DefaultTime,
    dismissible: true
  })
}

export { successMessage, InformationMessage, errorMessage, serverErrorMessage, warningMessage }
