<template>
  <div class="info-activities">
    <div class="info-activities-item" v-for="(item, index) in list" :key="'activities'+index">
      <div class="row">
        <div class="col">
          <div class="show-by">
            <template v-if="item.isManual">
              {{ item.by }} fez <b>uma Observação</b>
            </template>
            <template v-else>{{ item.by }} fez <b>{{ item.isStatusCheckIn ? 'check in' : 'check out' }} {{ item.details }} </b></template>
          </div>
        </div>
        <div class="col-auto">
          <div class="show-data">{{ $moment(item.at).format('LLL') }}</div>
        </div>
      </div>
      <template v-if="item.isManual">
        <div class="row">
          <div class="col-12">
            <div class="show-obs">{{ item.text }}</div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: 'info-check-in-activities-list',
  props: {
    list: Array
  }
}
</script>

<style lang="scss" scoped>
.info-activities-item {
  font-size: 12px;
  margin-bottom: 4px;
}
</style>
