<template>
  <div class="info-container">
    <div>
      <div class="info-label">Status</div>
      <div class="info-content">
        <info-check-in-status v-model="status" :set-async="changeStatus"/>
      </div>
    </div>
    <div>
      <div class="info-label">Atividades recentes</div>
      <div class="info-content">
        <info-check-in-activities-list :list="activitiesList"/>
      </div>
    </div>
    <div>
      <div class="info-label">Observação</div>
      <div class="info-content">
        <info-check-in-comment />
      </div>
    </div>
  </div>
</template>

<script>
import InfoCheckInActivitiesList from '@/modules/check-in/pages/info/info-check-in/info-check-in-activities-list'
import InfoCheckInStatus, { AVAILABLE_STATUS } from '@/modules/check-in/pages/info/info-check-in/info-check-in-status'
import { CHECK_IN_MODULE } from '@/modules/check-in'
import { errorMessage, successMessage } from '@/components/alertsMessages'
import { putManualCheckIn } from '@/modules/check-in/service/putManualCheckIn'
import { putManualCheckOut } from '@/modules/check-in/service/putManualCheckOut'
import { putManualUnseen } from '@/modules/check-in/service/putManualUnseen'
import { mapMutations } from 'vuex'
import InfoCheckInComment from '@/modules/check-in/pages/info/info-check-in/info-check-in-comment'

export default {
  name: 'info-check-in',
  components: { InfoCheckInComment, InfoCheckInStatus, InfoCheckInActivitiesList },
  data: () => ({
    status: null,
    rawCommentList: []
  }),
  computed: {
    currentId () {
      return this.$store.state[CHECK_IN_MODULE].showDetailId
    },
    item () {
      return this.$store.getters[CHECK_IN_MODULE + '/detailById'](this.currentId)
    },
    activitiesList () {
      const item = this.item
      if (item) {
        this.updateStatus(item.isStatusCheckIn, item.isStatusCheckOut)
        return item.activitiesList
      }
      return []
    }
  },
  methods: {
    ...mapMutations(CHECK_IN_MODULE, ['updateItemStatus']),
    updateStatus (isStatusCheckIn, isStatusCheckOut) {
      if (isStatusCheckIn) {
        this.status = AVAILABLE_STATUS.CHECK_IN
      } else if (isStatusCheckOut) {
        this.status = AVAILABLE_STATUS.CHECK_OUT
      } else {
        this.status = AVAILABLE_STATUS.AGUARDANDO
      }
    },
    async changeStatus (status, prevStatus) {
      let action = putManualUnseen
      if (status === AVAILABLE_STATUS.CHECK_IN) {
        action = putManualCheckIn
      } else if (status === AVAILABLE_STATUS.CHECK_OUT) {
        action = putManualCheckOut
      }
      try {
        const { message, ok } = await action(this.currentId)
        if (!ok) {
          const exc = { serverMessage: message }
          throw exc
        } else {
          successMessage(message ?? 'Ação realizada com sucesso.')
          this.updateItemStatus({
            id: this.currentId,
            isStatusCheckIn: status === AVAILABLE_STATUS.CHECK_IN,
            isStatusCheckOut: status === AVAILABLE_STATUS.CHECK_OUT,
            isStatusUnseen: status === AVAILABLE_STATUS.AGUARDANDO,
            userLoggedInName: this.$store.state.auth?.user?.name
          })
        }
      } catch ({ serverMessage }) {
        errorMessage(serverMessage ?? 'Ocorreu algum erro ao realizar essa ação.')
        this.status = prevStatus
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.info-container {
  .info-label {
    font-weight: 500;
    font-size: 14px;
    color: #000;
    margin-bottom: 14px;
  }

  .info-content {
    margin-bottom: 34px;
  }
}
</style>
