import http from '@/http'

export async function putComment (id: string, comment: string) {
  const response = await http.post('/admin/check-in/comment/' + id, { comment })
  if (response.status !== 200) {
    const exc: any = { CODID: 'OQ1KB918', response }
    exc['error'] = new Error(exc.CODID)
    throw exc
  }

  return {
    message: response.data.message,
    id: response.data.data.id
  }
}
