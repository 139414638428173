<template>
  <div class="info-container">
    <div>
      <div class="info-label">Código de identificação</div>
      <div class="info-content cod-id-text">{{ item.codId }}</div>
    </div>
    <div>
      <div class="info-label">Grupo</div>
      <div class="info-content d-flex">
        <div class="group-content">
          {{ item.grupo }}
        </div>
      </div>
    </div>
    <div>
      <div class="info-label">Tags</div>
      <div class="info-content">
        <div class="row">
          <div class="col-4 mb-2" v-for="item in item.tags" :key="'tag-'+item.id">
            <button
              type="button"
              @click="changeTag(item)"
              class="pill-tag"
              :class="{'pill-tag-checked': isTagChecked(item)}"
            >{{ item.text }}</button>
          </div>
        </div>
      </div>
    </div>
    <div>
      <div class="info-label">Histórico</div>
      <div class="info-content">
        <div class="historico-list">
          <div class="row" v-for="item in historicList" :key="'historico-'+item.id">
            <div class="col">{{ item.action }}</div>
            <div class="col">{{ $moment(item.at).format('L [às] LT') }}</div>
            <div class="col">{{ item.obs }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { CHECK_IN_MODULE } from '@/modules/check-in'
import { putTags } from '@/modules/check-in/service/putTags'
import { errorMessage, successMessage } from '@/components/alertsMessages'

export default {
  name: 'info-detail',
  data: () => ({
    softHistoricList: [],
    tagsToggled: {},
    execTimeOut: null
  }),
  computed: {
    isUserAdmin () {
      return this.$store.getters['auth/isAdmin']
    },
    currentId () {
      return this.$store.state[CHECK_IN_MODULE].showDetailId
    },
    item () {
      const item = this.$store.getters[CHECK_IN_MODULE + '/detailById'](this.currentId)
      const itemDetail = {
        codId: null,
        grupo: '',
        tags: [],
        historico: []
      }

      if (item) {
        itemDetail.codId = item.codId
        itemDetail.grupo = item.grupo
        itemDetail.tags = item.tags
        itemDetail.historico = item.historico
      }

      return itemDetail
    },
    historicList () {
      return [...this.item.historico, ...this.softHistoricList]
    }
  },
  methods: {
    isTagChecked (item) {
      if (this.tagsToggled[item.id] === undefined) {
        return item.isChecked
      }
      return this.tagsToggled[item.id]
    },
    changeTag (item) {
      if (!this.isUserAdmin) {
        return
      }
      this.$set(this.tagsToggled, item.id, !this.isTagChecked(item))
      this.updateTagUser()
    },
    updateTagUser () {
      const tags = []
      this.item.tags.forEach(tag => {
        if (this.isTagChecked(tag)) {
          tags.push(tag)
        }
      })
      clearTimeout(this.execTimeOut)
      this.execTimeOut = setTimeout(() => {
        putTags(this.currentId, tags.map(tag => tag.id))
          .then(({ ok, message }) => {
            if (ok) {
              successMessage(message)
              this.appendHistoric(tags)
            } else {
              errorMessage(message)
            }
          })
          .catch(() => errorMessage('Ocorreu um problema.'))
      }, 1400)
    },
    appendHistoric (tags) {
      this.softHistoricList.push({
        id: (Math.random() * 100 + 9000) >> 0,
        action: 'Alteração de TAG',
        at: new Date(),
        obs: 'Definiu TAG: ' + tags.map(tag => tag.text).join(', ')
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.info-container {
  .info-label {
    font-weight: 500;
    font-size: 14px;
    color: #000;
    margin-bottom: 14px;
  }

  .info-content {
    margin-bottom: 34px;
  }
}

.cod-id-text {
  font-size: 12px;
  font-weight: 300;
}

.group-content {
  color: #5E5E5E;
  background-color: #E4E6E9;
  font-size: 12px;
  font-weight: 300;
  padding: 7px 9px;
  width: content-box;
}

.pill-tag {
  border: 0;
  width: 100%;
  border-radius: 100px;
  font-weight: 300;
  padding: 8px 10px;
  font-size: 12px;
  text-align: center;
  background: #F3F3F3;
  color: #000000;

  &.pill-tag-checked {
    background: #09A96F;
    color: #FFFFFF;
  }
}

.historico-list {
  .row {
    margin-bottom: 6px;
  }
}
</style>
