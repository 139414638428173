<template>
  <div>
    <router-view/>
    <CheckInInfo/>
  </div>
</template>

<script>
import CheckInInfo from '@/modules/check-in/pages/info/info'
export default {
  name: 'CheckIn',
  components: { CheckInInfo }
}
</script>

<style scoped>

</style>
