import http from '@/http'

export async function putTags (id: string, tagsId: number[]) {
  const response = await http.put(`admin/check-in/participants/${id}/tags`, {
    tags: tagsId
  }, {
    validateStatus: () => true
  })
  return {
    message: response.data.message,
    ok: response.status === 200
  }
}
