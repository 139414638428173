<template>
  <div class="row row-cols-3 info-check-in-status">
    <div class="col">
      <b-button
        block
        :variant="isOutLine(AVAILABLE_STATUS.AGUARDANDO) + 'dark'"
        @click="() => setValue(AVAILABLE_STATUS.AGUARDANDO)"
        :disabled="disabled"
      >
        <i class="far fa-question-circle"></i>
        <div class="text-truncate">
          Aguardando
        </div>
      </b-button>
    </div>
    <div class="col">
      <b-button
        block
        :variant="isOutLine(AVAILABLE_STATUS.CHECK_IN) + 'success'"
        @click="() => setValue(AVAILABLE_STATUS.CHECK_IN)"
        :disabled="disabled"
      >
        <i class="fas fa-check-circle"></i>
        <div class="text-truncate">
          Check In
        </div>
      </b-button>
    </div>
    <div class="col">
      <b-button
        block
        :variant="isOutLine(AVAILABLE_STATUS.CHECK_OUT) + 'warning'"
        @click="() => setValue(AVAILABLE_STATUS.CHECK_OUT)"
        :disabled="disabled"
      >
        <i class='bx bx-log-out-circle'></i>
        <div class="text-truncate">
          Check Out
        </div>
      </b-button>
    </div>
  </div>
</template>

<script>
export const AVAILABLE_STATUS = {
  AGUARDANDO: 'AGUARDANDO',
  CHECK_IN: 'CHECK-IN',
  CHECK_OUT: 'CHECK-OUT'
}

export default {
  name: 'info-check-in-status',
  props: {
    value: {
      type: String,
      validator (v) {
        return [AVAILABLE_STATUS.AGUARDANDO, AVAILABLE_STATUS.CHECK_IN, AVAILABLE_STATUS.CHECK_OUT, null].includes(v)
      }
    },
    setAsync: Function
  },
  data: () => ({
    disabled: false,
    AVAILABLE_STATUS
  }),
  methods: {
    isOutLine (value) {
      return this.value === value ? '' : 'outline-'
    },
    async setValue (value) {
      const prevStatus = this.value
      this.$emit('input', value)
      this.disabled = true
      await this.setAsync(value, prevStatus)
      this.disabled = false
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~bootstrap/scss/functions";
@import "src/design/variables";

.info-check-in-status {
  --btn-padding: 0.37rem 0.08rem;
  --icon-size: 18px;
  --icon-mr: 4px;
  --gap: 4px;
  @media (min-width: map-get($grid-breakpoints, "md")) {
    --btn-padding: 0.47rem 0.75rem;
    --icon-size: 22px;
    --icon-mr: 12px;
    --gap: 12px;
  }

  button {
    padding: var(--btn-padding);
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    i {
      font-size: var(--icon-size);
      margin-right: var(--icon-mr);
    }
  }

  .col {
    &:not(:last-child) {
      padding-right: var(--gap);
    }

    &:not(:first-child) {
      padding-left: var(--gap);
    }
  }
}
</style>
