import http from '@/http'

export async function putManualUnseen (id: string) {
  const response = await http.post('/admin/check-in/unseen/' + id, {}, {
    validateStatus: () => true
  })
  if (response.status === 200) {
    return {
      message: response.data.message,
      ok: true
    }
  }

  if (response.status === 422) {
    return {
      message: response.data.message,
      ok: false
    }
  }

  const exc: any = { CODID: 'R3EEVGO1', response }
  exc['error'] = new Error(exc.CODID)
  throw exc
}
