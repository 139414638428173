<template>
  <div>
    <b-input-group>
      <template #prepend>
        <b-input-group-text>
          <i class='bx bx-chat'></i>
        </b-input-group-text>
      </template>
      <b-form-input
        placeholder="Registre uma ação manual"
        v-model="obsValue"
      ></b-form-input>
      <template #append>
        <b-button
          variant="outline-secondary"
          size="small"
          @click="addObs"
        ><i class='bx bx-send'></i></b-button>
      </template>
    </b-input-group>
    <div class="mt-4"></div>
    <div class="list-item mt-2" v-for="item in commentsList" :key="'comment-id-' + item.id">
      <div class="row">
        <div class="col">
          <div class="msg-comment">{{item.text}}</div>
        </div>
      </div>
      <div class="row">
        <div class="col">
            {{ item.by }}
        </div>
        <div class="col-auto">
          {{ $moment(item.at).format('LLL') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { errorMessage, successMessage } from '@/components/alertsMessages'
import { putComment } from '@/modules/check-in/service/putComment'
import { CHECK_IN_MODULE } from '@/modules/check-in'

export default {
  name: 'info-check-in-comment',
  data: () => ({
    obsValue: null,
    rawCommentList: []
  }),
  computed: {
    currentId () {
      return this.$store.state[CHECK_IN_MODULE].showDetailId
    },
    item () {
      return this.$store.getters[CHECK_IN_MODULE + '/detailById'](this.currentId)
    },
    commentsList () {
      const item = this.item
      if (item) {
        return [...this.rawCommentList, ...item.commentsList]
      }
      return [...this.rawCommentList]
    }
  },
  methods: {
    addObs () {
      if (!this.obsValue) {
        errorMessage('Digite um observação para ser registrada.')
        return
      }
      putComment(this.currentId, this.obsValue).then((resp) => {
        let message = resp.message
        if (!message) {
          message = 'Observação registrada.'
        }
        this.rawCommentList.unshift({
          id: resp.id,
          text: this.obsValue,
          by: this.$store.state.auth?.user?.name,
          at: new Date()
        })
        successMessage(message)
        this.obsValue = ''
      }).catch(() => errorMessage('Ocorru um erro para registrar sua observação'))
    }
  }
}
</script>

<style scoped>
.list-item {
  font-size: 12px;
}
</style>
